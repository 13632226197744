import React from "react"
import { themeGet } from 'styled-system'
import styled from 'styled-components'
import Hero from "../components/Hero"
import Layout from "../layouts"
import { Link, graphql, StaticQuery } from 'gatsby'
import MDXRenderer from "gatsby-mdx/mdx-renderer";

const HeaderList = styled.div`
	border: 1px solid #aaa;
	align-items: flex-start;
	display: grid;
	grid-template-columns: repeat(7, auto);
	grid-template-rows: repeat(auto, auto);
	grid-column-gap: 0.5em;
	grid-row-gap: 0.5em;
	margin: 2.5rem auto 0;
	padding: 1rem;
	text-align: left;
	font-weight: bold;
	position: relative;
	@media (max-width: ${themeGet('breakpoints.2')}) { 
		grid-template-columns: repeat(4, auto);
	}
	@media (max-width: ${themeGet('breakpoints.1')}) { 
		grid-template-columns: repeat(2, auto);
	}
`

const CategoryList = styled.div`
	border: 1px solid #aaa;
	border-top: 0px;
	align-items: flex-start;
	display: grid;
	flex-direction: row;
	grid-template-columns: repeat(7, 1fr);
	grid-template-rows: repeat(auto, auto);
	grid-column-gap: 1em;
	grid-row-gap: 1em;
	padding: 1rem;
	margin: 0 auto;
	text-align: left;
	position: relative;
	@media (max-width: ${themeGet('breakpoints.2')}) { 
		grid-template-columns: repeat(4, 1fr);
	}
	@media (max-width: ${themeGet('breakpoints.1')}) { 
		grid-template-columns: repeat(2, 1fr);
	}
`

const Category = styled.div`
	font-weight: bold;
	margin: 1rem 0 0;
`

const WebLink = styled.a`
	color: #074480;
`

class Start extends React.Component {
	constructor(props) {
		super(props)
		this.renderList = this.renderList.bind(this)
		this.renderColumn = this.renderColumn.bind(this)
	}

	renderList(data) {
		return data.map((item, i) =>{
			return(
				<div>
					<WebLink href={item.link}>{item.title}</WebLink>
				</div>
			)
		})
	}

	renderColumn(data) {
		return data.map((item, i) =>{
			return(
				<div>
					<Category>{item.category}</Category>
					<div>{this.renderList(item.list)}</div>
				</div>
			)
		})
	}

	render() {
		const {mdx} = this.props.data;
		return (
			<Layout>
				<HeaderList>
					{
						mdx.frontmatter.headerlist.map((item, i)=>{
							return(
								<div>
									<WebLink href={item.link}>{item.title}</WebLink>
								</div>
							)
						})
					}
				</HeaderList>
				<CategoryList>
					{
						mdx.frontmatter.categorylist.map((item,i)=>{
							return(
								<div>
									<div>{this.renderColumn(item.content)}</div>
								</div>
							)
						})
					}
				</CategoryList>
		  	</Layout>
		)
	}
}

export default Start

export const pageQuery = graphql`
  query StartQuery($id: String) {
    mdx(id: { eq: $id }) {
      	id
      	frontmatter {
       		title
			templateKey
			headerlist {
				title
				link
			}
			categorylist {
				column
				content {
					category
					list {
						title
						link
					}
				}
			}
      	}
      	code {
        	body
      	}
    }
  }
`;